@import "./bootstrap_custom";

.nav .nav-link {
  color: inherit;

  &.active, &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: .2ex;
  }
}

/// Common Layouts
.page {
  .nav-link {
    padding-left: 0;
  }
}

.page-content {
  h1, h2, h3, h4, h5, h6 {
    margin-block: 2rem;
  }

  p, ul, ol {
    font-size: 1.25rem;
    line-height: 1.8;
  }

  ul {
    padding-left: 1rem;

    li::marker {
      content: "- ";
    }

    li + li {
      margin-top: 30px;
    }

    .list {

    }
  }


  a {
    color: inherit;
    font-weight: 500;
    text-decoration-color: var(--bs-link-color);
    text-decoration-thickness: 2px;
    text-underline-offset: .2ex;

    &:hover {
      text-decoration-color: var(--bs-link-hover-color);
    }
  }

  .text-muted a {
    font-weight: inherit;
    text-decoration-color: var(--bs-gray);

    &:hover {
      color: var(--bs-gray-dark);
      text-decoration-color: var(--bs-gray-dark);
    }
  }
}

.front-image {
  width: 160px;
}

// see https://github.com/twbs/bootstrap-rubygem
@import "bootstrap/mixins/banner";
@include bsBanner("");

// chb custom
$font-size-base: 1rem;

$h1-font-size: $font-size-base * 3.3;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$headings-font-weight: 700;

$navbar-brand-font-size: 1.5rem;
$navbar-light-brand-color: rgba(22, 22, 22, 0.48);
$navbar-light-brand-hover-color: rgb(22, 22, 22);

$hr-margin-y: 6rem;

$list-inline-padding: .25rem;

// scss-docs-start import-stack
// Configuration
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/maps";
@import "bootstrap/mixins";
@import "bootstrap/utilities";

// Layout & components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
// @import "bootstrap/accordion";
@import "bootstrap/breadcrumb";
// @import "bootstrap/pagination";
@import "bootstrap/badge";
// @import "bootstrap/alert";
// @import "bootstrap/progress";
// @import "bootstrap/list-group";
// @import "bootstrap/close";
// @import "bootstrap/toasts";
// @import "bootstrap/modal";
// @import "bootstrap/tooltip";
// @import "bootstrap/popover";
// @import "bootstrap/carousel";
// @import "bootstrap/spinners";
// @import "bootstrap/offcanvas";
// @import "bootstrap/placeholders";

// Helpers
@import "bootstrap/helpers";

// Utilities
@import "bootstrap/utilities/api";
// scss-docs-end import-stack
